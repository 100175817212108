var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-breadcrumb',{attrs:{"items":[{
            text: 'ชุดตรวจทั้งหมด',
            to: { name: 'checklists-set-read' }
            },
            
            {
            text: 'จัดการชุดตรวจ'+_vm.Data.title,
            active: true,
            } ]}}),_c('Checklists-form',{attrs:{"is-stock-form-sidebar-active":_vm.isStockFormSidebarActive,"headertext":_vm.HeaderText,"stock-data":_vm.StockData,"groupchoice":_vm.group_choice},on:{"update:isStockFormSidebarActive":function($event){_vm.isStockFormSidebarActive=$event},"update:is-stock-form-sidebar-active":function($event){_vm.isStockFormSidebarActive=$event},"refetch-data":_vm.updatedData}}),_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"pt-1 pb-1 ml-2",attrs:{"lg":"9"}},[_c('h4',{staticClass:"c-gray-text font-weight-bold"},[_vm._v(_vm._s(_vm.Data.title))])]),_c('b-col',{staticClass:"pt-1 pb-1",attrs:{"lg":"2"}},[_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.addCourseService}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" เพิ่มรายการตรวจ ")],1)],1)])],1),_c('b-row',[_c('b-col',{staticClass:"pt-1 pb- ml-2",attrs:{"lg":"2"}},[_c('span',{staticClass:"c-gray-text font-weight-bold"},[_vm._v("รหัส: ")]),_c('span',{staticClass:"c-gray-text font-weight-bold"},[_vm._v(_vm._s(_vm.Data.code))])]),_c('b-col',{staticClass:"pt-1 pb-1",attrs:{"lg":"2"}},[_c('span',{staticClass:"c-gray-text font-weight-bold"},[_vm._v("ราคา: ")]),_c('span',{staticClass:"c-gray-text font-weight-bold"},[_vm._v(_vm._s(_vm.Data.price))])]),_c('b-col',{staticClass:"pt-1 pb-1",attrs:{"lg":"2"}},[_c('span',{staticClass:"c-gray-text font-weight-bold"},[_vm._v("สถานะ: ")]),_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":("light-" + (_vm.resolveUserStatusVariant(_vm.Data.is_active)))}},[_vm._v(" "+_vm._s(_vm.resolveUserStatusnameVariant(_vm.Data.is_active))+" ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }