<template>
  <div>
    <Checklists-form
      :is-stock-form-sidebar-active.sync="isStockFormSidebarActive"
      :headertext="HeaderText"
      :stock-data="StockData"
      :groupchoice="group_choice"
      @refetch-data="fetchData" 
    />
    <div>
      <template>
        <course-card @refetch-data="fetchData"/>
      </template>
    <b-card no-body>
    <div class="m-2">
            
            <vue-good-table
            :columns="tableColumns"
            :rows="Data"
            ref="refListTable"
            
            :select-options="{
                enabled: false,
                selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
              :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                }"
           
            
            >
            <template
            slot="table-row"
            slot-scope="props"
        >
                <span 
                v-if="props.column.field == 'is_active'"
                class="text-nowrap"
                >
                    <b-badge
                        pill
                        :variant="`light-${resolveUserStatusVariant(props.row.is_active)}`"
                        class="text-capitalize"
                    >
                        {{ resolveUserStatusnameVariant(props.row.is_active) }} 
                    </b-badge>
                </span>
                <span 
                v-else-if="props.column.field == 'group_name'"
                class="text-nowrap"
                >
                    <b-badge
                        pill
                        :variant="`light-${resolveMedicineVariant(props.row.group_name)}`"
                        class="text-capitalize"
                    >
                    <feather-icon :icon="resolveMedicineGroupnameVariant(props.row.group_name)" />
                    {{props.row.group_name}}
                    </b-badge>
                </span>
                <span
                v-else-if="props.column.field === 'action'"
                class="text-nowrap"
                >
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-info"
                    class="btn-icon rounded-circle"
                    @click="updateCourseStock(props.row)"

                >
                    <feather-icon icon="Edit3Icon" />
                </b-button>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    v-on:click="deleteconfirm(props.row.id)"
                >
                    <feather-icon icon="Trash2Icon" />
                </b-button>

                </span>
            </template>

            <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
             Items per page
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
           
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="Total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
             
              class="mt-1 mb-0"
            
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
            </vue-good-table>
    </div>
</b-card>
</div>
</div>
</template>
<script>
import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BFormSelect,BPagination,BBadge,
  BButton
 } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import { ref,onUnmounted } from '@vue/composition-api'
import store from '@/store'
import CourseCard from './ChecklistsCard.vue'
import checklistsSetStoreModule from '../checklistsSetStoreModule'
import useChecklistsList from './useChecklistsList'
import ChecklistsForm from './ChecklistsForm.vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import { async } from 'q'
 export default {
    directives: {
        Ripple,
    },
    components:{
        BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BFormSelect,BPagination,
        BButton,BBadge,
        CourseCard,
        FeatherIcon,
        VueGoodTable,
        ChecklistsForm
    },

    setup(){
        const group_choice = ref(1)
        const HeaderText = ref('')
        const isStockFormSidebarActive = ref(false)
        const StockData = ref({})
        const id = ref(router.currentRoute.params.id);
        const PATIENTS_STORE_MODULE_NAME = 'app-checklist-set';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, checklistsSetStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const {
            tableColumns,
            fetchData,
            pageLength,
            dir,
            Data,
            Total,
            currentPage,
            refListTable,
            deleteData,
            resolveUserStatusVariant,
            resolveUserStatusnameVariant,
            resolveMedicineVariant,
            resolveMedicineGroupnameVariant

        } = useChecklistsList(id);

        const updateCourseStock = (data)=>{
          data.is_active = false?data.is_active==0:true?data.is_active==1:false;
          StockData.value = data;
          HeaderText.value = 'แก้ไขรายการ';
          group_choice.value= data.group_id;
          isStockFormSidebarActive.value = true;
        }

        return {
            group_choice,
            HeaderText,
            isStockFormSidebarActive,
            tableColumns,
            updateCourseStock,
            StockData,
            id,
            fetchData,
            pageLength,
            dir,
            Data,
            Total,
            currentPage,
            refListTable,
            deleteData,
            resolveUserStatusVariant,
            resolveUserStatusnameVariant,
            resolveMedicineVariant,
            resolveMedicineGroupnameVariant
        }
        
    },methods:{
        handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
        },
        deleteconfirm(id){
         this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete stock ?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
           this.deleteData(id)
          }
        })

        },
    }
 }
</script>
