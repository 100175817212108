<template>
    <div>
    <b-breadcrumb
        :items="[{
                text: 'ชุดตรวจทั้งหมด',
                to: { name: 'checklists-set-read' }
                },
                
                {
                text: 'จัดการชุดตรวจ'+Data.title,
                active: true,
                },]"
      />
      
    <Checklists-form
      :is-stock-form-sidebar-active.sync="isStockFormSidebarActive"
      :headertext="HeaderText"
      :stock-data="StockData"
      :groupchoice="group_choice"
      @refetch-data="updatedData" 
    />
        <b-card no-body>
            <div class="m-2">
                <b-row>
                    <b-col lg="9" class="pt-1 pb-1 ml-2">
                        <h4 class="c-gray-text font-weight-bold">{{Data.title}}</h4>
                    </b-col>
                    <b-col class="pt-1 pb-1" lg="2">
                        <div>
                        <b-button 
                            @click="addCourseService"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary">
                            <feather-icon icon="PlusIcon" />
                            เพิ่มรายการตรวจ
                        </b-button>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="2" class="pt-1 pb- ml-2">
                        <span class="c-gray-text font-weight-bold">รหัส: </span>
                        <span class="c-gray-text font-weight-bold">{{Data.code}}</span>
                    </b-col>
                    <b-col lg="2" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">ราคา: </span>
                        <span class="c-gray-text font-weight-bold">{{Data.price}}</span>
                    </b-col>
                    <b-col lg="2" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">สถานะ: </span>
                        <b-badge
                        pill
                        :variant="`light-${resolveUserStatusVariant(Data.is_active)}`"
                        class="text-capitalize"
                        >
                            {{ resolveUserStatusnameVariant(Data.is_active) }} 
                        </b-badge>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>
<script>
import { BBreadcrumb } from 'bootstrap-vue'
 import {
   BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab, BFormSelect
 } from 'bootstrap-vue'
 //upload
 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BBadge
 } from 'bootstrap-vue'
import ChecklistsForm from './ChecklistsForm.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref,onUnmounted } from '@vue/composition-api'
import store from '@/store'
import checklistsSetStoreModule from '../checklistsSetStoreModule'
import useChecklistsList from './useChecklistsList'

 import Ripple from 'vue-ripple-directive'
 import router from '@/router'

 export default {
    directives: {
        Ripple,
    },
    components:{
        BBreadcrumb,
        BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab,
        BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BFormSelect,BBadge,
        ValidationProvider,
        ValidationObserver,
        ChecklistsForm
    },
    model: {
      prop: 'isStockFormSidebarActive',
      event: 'update:is-stock-form-sidebar-active',
    },
    setup(){
        const Data =ref({});
        const HeaderText = ref('')
        const isStockFormSidebarActive = ref(false)
        const StockData = ref({})
        const id = ref(router.currentRoute.params.id);
        const group_choice = ref(0)
        const PATIENTS_STORE_MODULE_NAME = 'app-checklist-set';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, checklistsSetStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });

        store.dispatch('app-checklist-set/fetchData', {id:id.value})
           .then((res) => {
                console.log(res.data.data[0]);
                Data.value = res.data.data[0];
            });
        

        const resolveUserStatusVariant = status => {
       
        if (status) return 'success'
        if (!status) return 'secondary'
        return 'primary'
        }
        const resolveUserStatusnameVariant = status => {
        
            if (status) return 'Active'
            if (!status) return 'Inactive'
            return 'primary'
        }


        
        const addCourseService = (data)=>{
        
        StockData.value = {};
        HeaderText.value = 'เพิ่มรายการตรวจ';
        group_choice.value = 1;
        isStockFormSidebarActive.value = true;
        }

        const {
            fetchData,
        } = useChecklistsList(id);

        function updatedData () {
        this.$emit('refetch-data')
        };

        return {
            updatedData,
            group_choice,
            fetchData,
            Data,
            addCourseService,
            StockData,
            HeaderText,
            isStockFormSidebarActive,
            resolveUserStatusVariant,
            resolveUserStatusnameVariant,

        }
        
    }
 }
</script>
<style>
.c-blue-header{
    color:#04497C !important;
}
.c-gray-text{
    color:#808080 !important;
}
.breakline-top{
    border-top:1px solid #D9D9D9 !important;
}
.breakline-bottom{
    border-bottom:1px solid #D9D9D9 !important;
}
</style>