import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useMeterialList(checklist_set_id) {
  const searchQuery = ref('');
  const selected_group = ref(0);
  const selected_category = ref(0);
  const selected_type = ref(0);
  const selected_status = ref(0);
  const refListTable = ref(null);
  const currentPage = ref(1);
  const pageLength = ref(10);
  const Total = ref(0);
  const Checklist_Data = ref([]);
  const dir = ref(false);
  const toast = useToast()
  const tableColumns = [

      { field: 'id', label: 'id', sortable: false, hidden:true },
        { field: 'code', label: 'รหัส', sortable: false },
        { field: 'title', label: 'ชื่อ', sortable: false },

        { field: 'price', label: 'ราคา', sortable: false },
        { field: 'is_active', label: 'สถานะ', sortable: false },
        { field: 'action', label: 'การจัดการ', sortable: false },


    ]
    const Data = ref([]);
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;

    const refetchData = () => {
      refListTable.value.refresh()
  }
    watch([currentPage, pageLength, searchQuery,selected_group,selected_category,selected_type,selected_status], () => {
      fetchData();
    })

    const fetchData = async () => {
        const offset = pageLength.value*(currentPage.value-1);
        Data.value  = [];
        await store
          .dispatch('app-checklist-set/fetchChecklistSetData', {
            limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            checklist_set_id:checklist_set_id.value,
            is_active:selected_status.value,
            branch_id:branch_id,
            order_by:'id DESC'
         
          })
          .then(response => {
          
            response.data.data.forEach((data) => data.balance = 1);
            
            Data.value = response.data.data;

            console.log('checklist value',Data.value);
            const total = response.data.total?response.data.total: Data.value.length;
           
            Total.value = total
          })
          .catch((error) => {
            console.log('error',error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching stock' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }

  fetchData();
  
  const fetchChecklistData  = async () => { 
    Checklist_Data.value  = [];
    await store.dispatch('app-checklist-set/fetchChecklistData',{
        is_active:1,
        branch_id:branch_id,
      })
      .then(response => {
        Checklist_Data.value = response.data.data;
      })
      .catch((error) => {
      console.log('error',error);
      })
  }

  fetchChecklistData();


  const deleteData = (id) => {
    //fetchDeleteProperty
    var data = {id:id}
    
    var res = store.dispatch('app-checklist-set/deleteChecklistSetData',data)
    res.then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      fetchData();
    }).catch(()=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Delete",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
    //console.log('id', id);
    
  }
  
  const resolveUserStatusVariant = status => {
       
    if (status) return 'success'
    if (!status) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusnameVariant = status => {
  
      if (status) return 'Active'
      if (!status) return 'Inactive'
      return 'primary'
  }
  const resolveMeterialVariant = status =>{
    if (status == 'วัสดุ') return 'dark'
    else if (status == 'บริการ') return 'warning'
    return 'light'
  }
  const resolveMeterialGroupnameVariant = status =>{
    if (status == 'วัสดุ') return 'ShieldIcon'
    else if (status == 'บริการ') return 'SmileIcon'
    return 'light'
  }
  const resolveMedicineVariant = status =>{
    if (status == 'ยา') return 'danger'
    else if (status == 'อุปกรณ์') return 'primary'
    else if (status == 'วัสดุ') return 'dark'
    else if (status == 'บริการ') return 'warning'
    return 'light'
  }
  const resolveMedicineGroupnameVariant = status =>{
    if (status == 'ยา') return 'HeartIcon'
    else if (status == 'อุปกรณ์') return 'SquareIcon'
    else if (status == 'วัสดุ') return 'ShieldIcon'
    else if (status == 'บริการ') return 'SmileIcon'
    return 'light'
  }
  const resolveBalanceVariant = balance =>{
    if (balance == 0 || balance == null) return 'text-danger'
  }
    return {
        fetchData,
        Checklist_Data,
        tableColumns,
        deleteData,
        Total,
        Data,
        pageLength,
        dir,
        searchQuery,
        selected_group,
        selected_category,
        selected_type,
        selected_status,
        currentPage,
        refListTable,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant,
        resolveMeterialVariant,
        resolveMeterialGroupnameVariant,
        resolveBalanceVariant,
        resolveMedicineVariant,
        resolveMedicineGroupnameVariant
    }
}